import UserContext from '../../../../core/context/user.context';
import { FC, useContext } from 'react';
import styles from './DesktopMenu.module.scss';
import { useTranslation } from 'react-i18next';
import ModalContext from '../../../../core/context/modal.context';
import { MODAL_IDENTIFIER } from '../../../../core/enum/modals.enum';
import Text from '../../../atoms/typographie/Text';
import NotificationBadge, { NOTIFICATION_BADGE_SIZE } from '../../../atoms/NotificationBadge';
import { Link, useLocation } from 'react-router-dom';
import { IMenuItemProps } from '../../../../core/const/menus';

interface ITabBarMenuItemProps {
  menuItemList: IMenuItemProps[];
}

const DesktopMenu: FC<ITabBarMenuItemProps> = ({ menuItemList }) => {
  const { openModal } = useContext(ModalContext);
  const { t: r } = useTranslation('routes');
  const { user } = useContext(UserContext);
  const location = useLocation();

  return (
    <ul className={styles.menu}>
      {menuItemList.map((menuItem, i) => {
        const isActive = location.pathname.includes(r(menuItem.link));
        return (
          <li key={`${menuItem.label}-${i}-${menuItem.link}`}>
            <Link
              className={`${styles.link} ${isActive ? styles.active : ''}`}
              to={menuItem.shouldOpenModal && !user ? undefined : (r(menuItem.link) as string)}
              onClick={
                !user && menuItem.shouldOpenModal ? () => openModal({ id: MODAL_IDENTIFIER.SIGN_IN }) : undefined
              }
            >
              <div className={styles.linkElement}>
                {menuItem.notifications ? (
                  <div className={styles.notificationsWrapper}>
                    <NotificationBadge
                      size={NOTIFICATION_BADGE_SIZE.SMALL}
                      count={typeof menuItem.notifications === 'number' ? menuItem.notifications : '!'}
                    />
                  </div>
                ) : null}
                <span className={`${styles.menuItemLabel} ${isActive ? styles.active : ''}`}>
                  <Text tag={'span'} bold>
                    {r(menuItem.label)}
                  </Text>
                </span>
              </div>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default DesktopMenu;
