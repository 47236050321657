import { Drawer } from '@mui/material';
import { FC } from 'react';
import { IApplication, ITenantProfile } from '@wohnsinn/ws-ts-lib';
import styles from './ApplicantModal.module.scss';
import Chat from '../Chat';
import { useTranslation } from 'react-i18next';
import Headline from 'component/atoms/typographie/Headline';
import CloseButton from 'component/atoms/Buttons/CloseButton';
import ApplicantInformationModal from 'component/organisms/ApplicantInformationModal';

const ApplicationModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  application: IApplication;
  tenantProfile: ITenantProfile;
  landlordId: string;
  apartmentId: string;
}> = ({ isOpen, onClose, application, tenantProfile, apartmentId, landlordId }) => {
  const { t } = useTranslation('common');
  return (
    <Drawer anchor={'right'} open={isOpen} onClose={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Headline>{t('component.organisms.ApplicantInformation.selfDisclosure')}</Headline>
          <CloseButton onClose={onClose} color={'dark'} />
        </div>

        <div className={styles.content}>
          <div className={styles.left}>
            <ApplicantInformationModal
              showHeader={false}
              apartmentId={apartmentId}
              tenantProfile={tenantProfile}
              landlordId={landlordId}
              onClose={onClose}
            />
          </div>

          <div className={styles.right}>
            <Chat customStyling={styles.chat} key={application.apartmentId} application={application} />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ApplicationModal;
