import { FC, useContext, useEffect, useState } from 'react';

import { IApplication, ITenantProfile, IUpload } from '@wohnsinn/ws-ts-lib';
import LightShow from 'component/molecules/LightShow';
import { MEDIA_TYPE } from '@wohnsinn/ws-ts-lib/dist/types/media';
import { Grid } from '@mui/material';
import Avatar, { AVATAR_SIZE } from 'component/atoms/Avatar';
import LandlordRatingButtons from 'component/molecules/LandlordRatingButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { faBalanceScale } from '@fortawesome/pro-light-svg-icons';
import styles from './ApplicantSelfDisclosure.module.scss';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import { printAge } from 'core/helper/print-age';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import { FormContext } from 'core/context/form.context';
import TextEditor, { TEXT_EDITOR_SIZE } from 'component/atoms/formElement/TextEditor';
import CTAButton from 'component/atoms/Buttons/CTAButton';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { wohnsinnServices } from 'App';
import ModalContext from 'core/context/modal.context';
import DownloadItemsList from 'component/molecules/DownloadItemsList';
import useTenantDocuments from 'core/hook/tenant-document.hook';
import ListWithIcons, { IListWithIconItem } from 'component/molecules/ListWithIcons';
import TenantAboutMeList from 'component/organisms/TenantProfile/TenantAboutMeList';
import TenantHouseholdList from 'component/organisms/TenantProfile/TenantHouseholdList';
import TenantGeneralList from 'component/organisms/TenantProfile/TenantGeneralList';
import TenantFinanceList from 'component/organisms/TenantProfile/TenantFinanceList';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import sanitize from 'sanitize-html';
import truncateText from 'core/helper/truncate-text';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import CloseButton from 'component/atoms/Buttons/CloseButton';

const ApplicantSelfDisclosure: FC<{
  tenantProfile: ITenantProfile;
  application: IApplication;
  showHeader?: boolean;
  onClose?: () => void;
}> = ({ tenantProfile, application, showHeader, onClose }) => {
  const { t } = useTranslation('common');
  return (
    <>
      {showHeader ? (
        <div className={styles.header}>
          <Headline>{t('component.organisms.ApplicantInformation.selfDisclosure')}</Headline>
          <CloseButton onClose={onClose} color={'dark'} />
        </div>
      ) : null}
      <Grid container spacing={4} p={4}>
        <Grid item xs={12} md={4}>
          <AvatarSection tenantProfile={tenantProfile} application={application} />
        </Grid>
        <Grid item xs={12} md={8}>
          <GeneralSection tenantProfile={tenantProfile} />
        </Grid>
      </Grid>

      <div className={styles.horizontalDivider} />

      <Grid container spacing={4} p={4}>
        <Grid item xs={12} className={styles.sectionWrapper}>
          <AboutTenantSection tenantProfile={tenantProfile} />
          <FinancialSection tenantProfile={tenantProfile} />
          <HouseholdSection tenantProfile={tenantProfile} />
          <DocumentsSection tenantProfile={tenantProfile} />
        </Grid>
      </Grid>

      <div className={styles.horizontalDivider} />

      <Grid container spacing={4} p={4}>
        <Grid item xs={12}>
          <LegalSection tenantProfile={tenantProfile} />
        </Grid>
      </Grid>

      <div className={styles.horizontalDivider} />

      <Grid container spacing={4} p={4}>
        <Grid item xs={12}>
          <NotesSection application={application} />
        </Grid>
      </Grid>
    </>
  );
};

/************************************/
/********** SECTIONS ****************/
/************************************/

const AvatarSection: FC<{ tenantProfile: ITenantProfile; application: IApplication }> = ({
  tenantProfile,
  application,
}) => {
  const [isLightShowOpen, setIsLightShowOpen] = useState<boolean>(false);
  return (
    <>
      {tenantProfile?.photoUrl || tenantProfile?.aboutMe?.introductionVideo?.url ? (
        <LightShow
          open={isLightShowOpen}
          onClose={() => setIsLightShowOpen(false)}
          media={
            tenantProfile?.aboutMe?.introductionVideo?.url
              ? [
                  {
                    alt: tenantProfile?.personalInformation?.firstName,
                    creatorId: 'non',
                    id: 'string',
                    mediaType: MEDIA_TYPE.VIDEO,
                    thumbnailUrl: tenantProfile?.photoUrl,
                    updatedAt: new Date(),
                    url: tenantProfile?.aboutMe.introductionVideo.url,
                    width: 250,
                  },
                ]
              : [
                  {
                    alt: tenantProfile?.personalInformation?.firstName,
                    creatorId: 'non',
                    id: 'string',
                    mediaType: MEDIA_TYPE.IMAGE,
                    thumbnailUrl: tenantProfile?.photoUrl,
                    updatedAt: new Date(),
                    url: tenantProfile?.photoUrl,
                    width: 250,
                  },
                ]
          }
        />
      ) : null}
      <div className={styles.mediaWrapper}>
        {tenantProfile?.aboutMe?.introductionVideo?.url ? (
          <button onClick={() => setIsLightShowOpen(true)} className={styles.playButton}>
            <FontAwesomeIcon icon={faPlay} />
          </button>
        ) : null}
        <Avatar onClick={() => setIsLightShowOpen(true)} size={AVATAR_SIZE.auto} avatarUrl={tenantProfile?.photoUrl} />
      </div>
      <LandlordRatingButtons
        big={false}
        landlordRating={application?.landlordRating}
        tenantProfileId={tenantProfile.uid}
      />
    </>
  );
};

const GeneralSection: FC<{ tenantProfile: ITenantProfile }> = ({ tenantProfile }) => {
  const { t } = useTranslation('common');
  const { openModal } = useContext(ModalContext);

  const tenantAboutMeTextSanitized = sanitize(tenantProfile?.aboutMe?.description, {
    allowedTags: [],
  })?.replace(/&amp;/g, ' & ');
  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <Headline>{`${tenantProfile?.personalInformation?.firstName} ${
          tenantProfile?.personalInformation?.lastName
        }, ${printAge(tenantProfile?.personalInformation?.dateOfBirth)}`}</Headline>
      </div>

      <TenantGeneralList tenantProfile={tenantProfile} />

      <div style={{ marginTop: 16 }}>
        {tenantAboutMeTextSanitized?.length <= 160 ? (
          <Text>{tenantAboutMeTextSanitized}</Text>
        ) : (
          <button
            onClick={() =>
              openModal({
                id: MODAL_IDENTIFIER.INFO_TEXT,
                data: {
                  title: t('aboutMe'),
                  sanitizeText: tenantAboutMeTextSanitized,
                },
              })
            }
          >
            <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>{truncateText(tenantAboutMeTextSanitized, 160)}</Text>
            <div style={{ marginTop: 16 }}>
              <Text bold color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
                {`${t('showMore')} >`}
              </Text>
            </div>
          </button>
        )}
      </div>
    </>
  );
};

const AboutTenantSection: FC<{ tenantProfile: ITenantProfile }> = ({ tenantProfile }) => {
  const { t } = useTranslation('common');
  return (
    <div className={`${styles.section} ${styles.left} ${styles.top}`}>
      <div className={styles.inner}>
        <Headline size={HEADLINE_SIZE.H3}>{t('aboutMe')}</Headline>
        <Spacer size={SPACER_SIZE.MD} />
        <TenantAboutMeList tenantProfile={tenantProfile} />
      </div>
    </div>
  );
};
const FinancialSection: FC<{ tenantProfile: ITenantProfile }> = ({ tenantProfile }) => {
  const { t } = useTranslation('common');
  return (
    <div className={`${styles.section} ${styles.right} ${styles.top}`}>
      <div className={styles.inner}>
        <Headline size={HEADLINE_SIZE.H3}>{t('financialSituation')}</Headline>
        <Spacer size={SPACER_SIZE.MD} />
        <TenantFinanceList rent={1500} tenantProfile={tenantProfile} />
      </div>
    </div>
  );
};
const HouseholdSection: FC<{ tenantProfile: ITenantProfile }> = ({ tenantProfile }) => {
  const { t } = useTranslation('common');
  return (
    <div className={`${styles.section} ${styles.left}`}>
      <div className={styles.inner}>
        <Headline size={HEADLINE_SIZE.H3}>{t('myHousehold')}</Headline>
        <Spacer size={SPACER_SIZE.MD} />
        <TenantHouseholdList tenantProfile={tenantProfile} />
      </div>
    </div>
  );
};
const DocumentsSection: FC<{ tenantProfile: ITenantProfile }> = ({ tenantProfile }) => {
  const { schufaCheckDocumentList, incomeProofDocumentList } = useTenantDocuments(tenantProfile?.uid);
  const [schufaCheckDocumentUploadList, setSchufaCheckUploadList] = useState<IUpload[]>([]);
  const [incomeProofDocumentUploadList, setIncomeProofDocumentUploadList] = useState<IUpload[]>([]);
  const { t } = useTranslation('common');

  useEffect(() => {
    setSchufaCheckUploadList(schufaCheckDocumentList);
    setIncomeProofDocumentUploadList(incomeProofDocumentList);
  }, [schufaCheckDocumentList, incomeProofDocumentList]);

  return (
    <div className={`${styles.section} ${styles.right}`}>
      <div className={styles.inner}>
        <Headline size={HEADLINE_SIZE.H3}>{t('component.organisms.ApplicantInformation.documents')}</Headline>
        <Spacer size={SPACER_SIZE.MD} />
        {!schufaCheckDocumentUploadList?.length && !incomeProofDocumentUploadList?.length ? (
          <Text>{t('applicantHasNoDocuments')}</Text>
        ) : (
          <>
            <DownloadItemsList label={t('schufa')} list={schufaCheckDocumentUploadList} />
            <DownloadItemsList label={t('incomeProof')} list={incomeProofDocumentUploadList} />
          </>
        )}
      </div>
    </div>
  );
};

const LegalSection: FC<{ tenantProfile: ITenantProfile }> = ({ tenantProfile }) => {
  const { t } = useTranslation('common');

  const legalItems: IListWithIconItem[] = [];

  legalItems.push({
    icon: faBalanceScale,
    text: tenantProfile?.personalInformation?.hadEvictions
      ? t('tenant.legalItems.hadEvictions.not')
      : t(`tenant.legalItems.hadEvictions.not`),
  });

  legalItems.push({
    icon: faBalanceScale,
    text: tenantProfile?.personalInformation?.hasInsolvencyProceedings
      ? t('tenant.legalItems.hasInsolvencyProceedings.not')
      : t(`tenant.legalItems.hasInsolvencyProceedings.not`),
  });

  return (
    <>
      {/* LEGAL ITEMS */}
      <Headline size={HEADLINE_SIZE.H3}>{t('tenant.legalItems.title')}</Headline>
      <Spacer size={SPACER_SIZE.MD} />
      <ListWithIcons list={legalItems} />
    </>
  );
};

const NotesSection: FC<{ application: IApplication }> = ({ application }) => {
  const [buttonSubmitMode, setButtonSubmitMode] = useState(SUBMIT_BUTTON_MODE.NONE);
  const { applicationService } = wohnsinnServices;
  const { closeModal } = useContext(ModalContext);
  const { t } = useTranslation('common');

  const Note_Scheme: any = {
    notes: Joi.string().optional(),
  };

  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<{ notes?: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      notes: application?.notes,
    },
    resolver: joiResolver(Joi.object(Note_Scheme)),
  });

  const submitForm = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    const data: Partial<IApplication> = {
      notes: watch('notes'),
    };
    try {
      await applicationService.updateApplication({
        landlordId: application?.landlordId,
        apartmentId: application?.apartmentId,
        tenantId: application?.tenantProfile?.uid,
        data,
      });

      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
      closeModal();
    } catch (e) {
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
      console.error('Error on invite user modal', e);
    }
  };

  return (
    <>
      {/* LEGAL ITEMS */}
      <Headline size={HEADLINE_SIZE.H3}>{t('view.ApartmentOverviewView.notes.label')}</Headline>
      <Spacer size={SPACER_SIZE.MD} />
      <FormContext.Provider value={{ control }}>
        <form
          id={'notesForm'}
          autoComplete={'off'}
          onSubmit={handleSubmit(submitForm, (error) => console.error(error))}
        >
          <TextEditor hideToolbar={true} name={'notes'} size={TEXT_EDITOR_SIZE.MEDIUM} placeholder={t('notes')} />
        </form>
      </FormContext.Provider>

      <CTAButton
        rounded={false}
        buttonText={t('save')}
        expand={'full'}
        form={'notesForm'}
        disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || !isValid}
      />
    </>
  );
};

export default ApplicantSelfDisclosure;
