import { FC } from 'react';
import styles from './ActionBanner.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../typographie/Text';
import { faSpinner, faTimes } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

export interface IActionBannerProps {
  onClick?: () => any;
  closeAction?: () => any;
  buttonText?: string;
  isLoading?: boolean;
  label: string;
}

const ActionBanner: FC<IActionBannerProps> = ({ onClick, closeAction, label, buttonText, isLoading = false }) => {
  const { t } = useTranslation('common');
  return (
    <div className={styles.wrapper}>
      <div className={styles.leftRightPart}>
        {closeAction ? (
          <button onClick={closeAction}>
            <span className="sr-only">{t('close')}</span>
            <FontAwesomeIcon icon={faTimes} size={'lg'} />
          </button>
        ) : null}
      </div>

      <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag={'span'} color={TEXT_COLOR.TEXT_COLOR_DARK}>
        {label}
      </Text>

      <div className={styles.leftRightPart}>
        {isLoading ? (
          <FontAwesomeIcon spin={true} width={'23px'} height={'23px'} icon={faSpinner} size={'1x'} />
        ) : onClick ? (
          <button onClick={onClick}>
            <Text color={TEXT_COLOR.TEXT_COLOR_PRIMARY} type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag={'span'}>
              {buttonText}
            </Text>
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default ActionBanner;
