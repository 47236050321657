import { FC, MouseEvent, useState } from 'react';
import { APARTMENT_RENT_STATUS, APPLICATION_STATE, IApplication } from '@wohnsinn/ws-ts-lib';
import styles from './ActiveTenantCard.module.scss';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { Avatar, Menu, MenuItem } from '@mui/material';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_VARIANT } from 'component/atoms/typographie/Text';
import formatDate from 'core/helper/convert-date-to-last-message-date';
import NotificationBadge, { NOTIFICATION_BADGE_SIZE } from 'component/atoms/NotificationBadge';
import LastTenantMessagePreview from 'component/molecules/LastTenantMessagePreview';
import { wohnsinnServices } from 'App';
import ApplicationModal from 'component/organisms/ApplicationModal';
import { useQueryClient } from '@tanstack/react-query';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

const ActiveTenantCard: FC<{ application: IApplication }> = ({ application }) => {
  const { chatService, apartmentService, applicationService } = wohnsinnServices;
  const [submitButtonMode, setSubmitButtonMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const [applicationModalIsOpen, setApplicationModalIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const removeActiveTenant = async () => {
    setSubmitButtonMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      await apartmentService.updateApartment({
        data: {
          rentStatus: APARTMENT_RENT_STATUS.NONE,
          activeTenantId: null,
        },
        creatorId: application.landlordId,
        apartmentId: application.apartmentId,
      });

      await applicationService.changeApplicationState({
        applications: application,
        applicationState: APPLICATION_STATE.DELETED,
      });

      await queryClient.invalidateQueries({ queryKey: ['activeTenantApplication', application.apartmentId] });

      setSubmitButtonMode(SUBMIT_BUTTON_MODE.SUCCESS);
      setAnchorEl(null);
    } catch (e) {
      setSubmitButtonMode(SUBMIT_BUTTON_MODE.ERROR);
      console.error('error on removeActiveTenant', e);
    }
  };

  const handleClickOnApplicantInfo = async () => {
    await chatService.clearUnreadTenantMessagesRefOnApplication(
      application.landlordId,
      application.apartmentId,
      application.tenantProfile.uid
    );

    await chatService.clearUnreadTenantChatsRefOnApartment(
      application.apartmentId,
      application.landlordId,
      application.tenantProfile.uid
    );

    setApplicationModalIsOpen(true);
  };

  return (
    <div className={styles.wrapper}>
      <ApplicationModal
        apartmentId={application.apartmentId}
        tenantProfile={application.tenantProfile}
        landlordId={application.landlordId}
        application={application}
        isOpen={applicationModalIsOpen}
        onClose={() => setApplicationModalIsOpen(false)}
      />
      <Menu
        id="active-tenant-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={removeActiveTenant}>
          {submitButtonMode === SUBMIT_BUTTON_MODE.SUBMITTING ? (
            <>
              <FontAwesomeIcon icon={faSpinner} spin />
              <div style={{ width: 8 }} />
            </>
          ) : null}
          Mieter entfernen
        </MenuItem>
      </Menu>
      <div className={styles.header}>
        <Headline size={HEADLINE_SIZE.H3}>Aktuelles Mietverhältnis</Headline>
        <CTAButton
          hideText
          size={'small'}
          buttonText={'Active Tenant Settings'}
          buttonStyle={BUTTON_STYLE.SECONDARY}
          onClick={handleClick}
          icon={faEllipsis}
          color={TEXT_COLOR.TEXT_COLOR_DARK}
        />
      </div>

      <button onClick={handleClickOnApplicantInfo} className={styles.content}>
        <div className={styles.tenant}>
          <Avatar
            sx={{
              width: {
                xs: 28,
                sm: 42,
              },
              height: {
                xs: 28,
                sm: 42,
              },
            }}
            alt={`${application?.tenantProfile?.personalInformation?.firstName} ${application?.tenantProfile?.personalInformation?.lastName}`}
            src={application?.tenantProfile?.photoUrl}
          />
          <div style={{ width: '100%' }}>
            <div>
              <Text>{`${application?.tenantProfile?.personalInformation?.firstName} ${application?.tenantProfile?.personalInformation?.lastName}`}</Text>
              <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>{`${t('numberOfPeople', {
                count: parseInt(application?.tenantProfile?.household?.numberOfPeopleMovingIn as unknown as string),
              })} • ${application.tenantProfile.household.monthlyIncome} €`}</Text>
            </div>
          </div>
        </div>
        <div className={styles.message}>
          {application?.lastMessage && (
            <LastTenantMessagePreview
              unreadTenantMessagesRefLength={application.unreadTenantMessagesRef?.length}
              tenantProfileId={application?.tenantProfile?.uid}
              lastMessageSenderId={application?.lastMessageSenderId}
              lastMessage={application.lastMessage}
            />
          )}
          <div style={{ display: 'flex', gap: 8 }}>
            {application?.unreadTenantMessagesRef?.length ? (
              <NotificationBadge
                size={NOTIFICATION_BADGE_SIZE.MEDIUM}
                count={application?.unreadTenantMessagesRef.length}
              />
            ) : null}
            <Text tag={'span'} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL} type={TEXT_TYPE.TEXT_TYPE_CAPTION}>
              {!!application?.lastMessageSent ? formatDate(application?.lastMessageSent) : ''}
            </Text>
          </div>
        </div>
      </button>
    </div>
  );
};

export default ActiveTenantCard;
