import { FC } from 'react';
import ListWithIcons, { IListWithIconItem } from '../../../molecules/ListWithIcons';
import { faCircleEuro, faPercentage, faHouse } from '@fortawesome/pro-light-svg-icons';
import { faThumbsDown, faThumbsUp, faThumbTack } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { ITenantProfile } from '@wohnsinn/ws-ts-lib';
import { formatNumberGermanStyle } from 'core/helper/get-formatted-price';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const getThumbIcon = (ratio: number): { icon: IconProp; color: 'green' | 'red' | 'yellow' } => {
  if (ratio <= 30) {
    return { icon: faThumbsUp, color: 'green' };
  } else if (ratio <= 35) {
    return { icon: faThumbTack, color: 'yellow' };
  } else {
    return { icon: faThumbsDown, color: 'red' };
  }
};

const TenantFinanceList: FC<{ tenantProfile: ITenantProfile; rent: number }> = ({ tenantProfile, rent }) => {
  const { t } = useTranslation('common');
  const financeList: IListWithIconItem[] = [];
  const ratio = (rent / tenantProfile?.household?.monthlyIncome) * 100;

  financeList.push({
    icon: faCircleEuro,
    text: t('monthlyIncome.text', { income: formatNumberGermanStyle(tenantProfile?.household?.monthlyIncome) }),
  });
  financeList.push({
    icon: faHouse,
    text: t('newRent', { rent: formatNumberGermanStyle(rent) }),
  });

  financeList.push({
    icon: faPercentage,
    text: t('rentBurdenRatio', { ratio }),
    iconAfter: getThumbIcon(ratio),
  });

  return <ListWithIcons list={financeList} />;
};

export default TenantFinanceList;
