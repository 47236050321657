import ModalWrapper, { IModalWrapperButtonProps } from '../../ModalWrapper';
import { useTranslation } from 'react-i18next';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import ModalContext from 'core/context/modal.context';
import { useContext } from 'react';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import UserContext from 'core/context/user.context';
import { wohnsinnServices } from 'App';
import SnackBarContext from 'core/context/snackbar.context';

const EmailVerificationModal = () => {
  const { closeModal } = useContext(ModalContext);
  const { firebaseAuthService } = wohnsinnServices;
  const { activeUserType, landlordProfile, tenantProfile } = useContext(UserContext);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.signIn.ConfirmEmailModal' });
  const email = localStorage.getItem('userC');

  const resendVerificationMail = async (): Promise<void> => {
    const email: string = activeUserType === USER_TYPE.LANDLORD ? landlordProfile.email : tenantProfile.email;
    try {
      await firebaseAuthService.triggerSendVerificationLink(email, activeUserType);
      handleSnackBar('toast.resendEmailVerificationMail', 'success');
    } catch (e) {
      console.error('Error on resendVerificationMail', e);
    }
  };

  const MODAL_BUTTONS: IModalWrapperButtonProps = {
    primary: {
      onClick: closeModal,
      buttonText: t('ok'),
    },
  };

  return (
    <ModalWrapper title={m('title')} icon={t('pictogram.mail')} buttons={MODAL_BUTTONS}>
      <Text align={'center'}>{m('text', { replace: { email } })}</Text>
      <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} bold align={'center'}>
        {m('subText')}
        <button onClick={resendVerificationMail}>
          <Text color={TEXT_COLOR.TEXT_COLOR_PRIMARY} type={TEXT_TYPE.TEXT_TYPE_CAPTION} bold>
            {m('sendNew')}
          </Text>
        </button>
      </Text>
    </ModalWrapper>
  );
};

export default EmailVerificationModal;
