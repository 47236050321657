import { FC, useContext, useEffect, useState } from 'react';
import { APARTMENT_TYPE_OF_MARKETING, IApartment, IOrganization } from '@wohnsinn/ws-ts-lib';
import styles from './ApartmentInformation.module.scss';
import ApartmentInformationLocation from '../../atoms/ApartmentInformationLocation/';
import { useTranslation } from 'react-i18next';
import ApartmentBasics from '../../molecules/ApartmentBasics';
import ApartmentEquipment from '../../molecules/ApartmentEquipment';
import ApartmentCosts, { IApartmentCostsProps } from '../../molecules/ApartmentCosts';
import VirtualTour from '../../atoms/VirtualTour';
import Tag from '../../atoms/Tag';
import UserContext from '../../../core/context/user.context';
import ApartmentContactPersonCard from '../../molecules/ApartmentContactPersonCard';
import ApartmentEnergySupply from '../../molecules/ApartmentEnergySupply';
import useWindowSize from '../../../core/hook/windowsize.hook';
import ModalContext from '../../../core/context/modal.context';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import LightShow from '../../molecules/LightShow';
import Text, { TEXT_COLOR } from '../../atoms/typographie/Text';
import Headline, { HEADLINE_SIZE } from '../../atoms/typographie/Headline';
import ApartmentRent from '../../molecules/ApartmentRent';
import sanitize from 'sanitize-html';
import ImageGallery from '../../molecules/ImageGallery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faLink } from '@fortawesome/pro-solid-svg-icons';
import MapModal from '../MapModal';
import { Drawer } from '@mui/material';
import ApartmentBadge from '../../atoms/ApartmentBadge';
import { createApartmentCosts } from 'component/organisms/ApartmentInformation/create-apartment-costs';
import ApartmentOrganizationCard from 'component/molecules/ApartmentOrganizationCard';
import { wohnsinnServices } from 'App';
import ApartmentAreas from 'component/molecules/ApartmentAreas';
import ApartmentRooms from 'component/molecules/ApartmentRooms';
import { Link } from 'react-router-dom';
import validateLink from 'core/helper/validate-link';
import ApartmentLocationMap from 'component/molecules/ApartmentLocationMap';
import InfoBox from 'component/atoms/InfoBox';
import PremiumSubscriptionDrawer from 'component/atoms/PremiumSubscriptionDrawer';
import truncateText from 'core/helper/truncate-text';

export interface IApartmentInformationProps {
  apartment: IApartment;
  backRoute?: string;
}

const ApartmentInformation: FC<IApartmentInformationProps> = ({ apartment }) => {
  const { t } = useTranslation('common');
  const { user } = useContext(UserContext);
  const { isGreaterMd } = useWindowSize();
  const [mapIsOpen, setMapIsOpen] = useState<boolean>(false);
  const { openModal } = useContext(ModalContext);
  const [isLightShowOpen, setIsLightShowOpen] = useState<boolean>(false);
  const [slideShowIndex, setSlideShowIndex] = useState<number>(0);
  const customEquipmentDescription = apartment?.equipment?.description;
  const customEquipmentDescriptionLength = sanitize(apartment?.equipment?.description, {
    allowedTags: [],
  })?.length;
  const [showSubscriptionDrawer, setShowSubscriptionDrawer] = useState<boolean>(false);
  const customEnergyDescription = apartment?.energySupply?.description;
  const customGenerellDescription = apartment?.freeTexts?.generellDescription;
  const customLocationDescription = apartment?.freeTexts?.location;
  const customOtherDescription = apartment?.freeTexts?.other;
  const apartmentIsUnpublished = !apartment?.isPublished && !apartment?.editorList?.includes(user?.uid);
  const [organization, setOrganization] = useState<IOrganization>(null);
  const { organizationService } = wohnsinnServices;

  const fetchLandlordProfile = async (): Promise<void> => {
    const organization = await organizationService.getOrganizationById(apartment.organizationId);
    setOrganization(organization);
  };

  useEffect(() => {
    fetchLandlordProfile();
  }, [apartment]);

  const customOtherDescriptionSanitized = sanitize(apartment?.freeTexts?.other, {
    allowedTags: [],
  })?.replace(/&amp;/g, ' & ');

  const freeTextPriceSanitized = sanitize(apartment?.cost?.freeTextPrice, {
    allowedTags: [],
  })?.replace(/&amp;/g, ' & ');

  const customLocationDescriptionSanitized = sanitize(apartment?.freeTexts?.location, {
    allowedTags: [],
  })?.replace(/&amp;/g, ' & ');

  const customGenerellDescriptionSanitized = sanitize(apartment?.freeTexts?.generellDescription, {
    allowedTags: [],
  })?.replace(/&amp;/g, ' & ');

  // Add additional costs to apartment costs
  const costs: IApartmentCostsProps = createApartmentCosts(apartment);

  useEffect(() => {
    if (mapIsOpen && isGreaterMd) {
      setMapIsOpen(false);
    }
  }, [isGreaterMd]);

  const handleGalleryClick = (index: number) => {
    setSlideShowIndex(index);
    setIsLightShowOpen(true);
  };

  return (
    <div key={apartment.id} className={`${styles.informationWrapper}`}>
      {apartment ? (
        <Drawer anchor={'right'} open={mapIsOpen} onClose={() => setMapIsOpen(false)}>
          <MapModal onClose={() => setMapIsOpen(false)} apartment={apartment} />
        </Drawer>
      ) : null}
      {apartment && apartment?.isExclusive ? (
        <Drawer open={showSubscriptionDrawer} anchor={'right'} sx={{ width: '100vw' }}>
          <PremiumSubscriptionDrawer closeAction={() => setShowSubscriptionDrawer(false)}></PremiumSubscriptionDrawer>
        </Drawer>
      ) : null}

      <div className={styles.apartmentCard}>
        <ApartmentBadge apartmentIsExclusive={apartment?.isExclusive} isDisabled={apartmentIsUnpublished} />
        {apartment.media && apartment.media.length ? (
          <LightShow
            media={apartment.media}
            openAtIndex={slideShowIndex}
            open={isLightShowOpen}
            onClose={() => setIsLightShowOpen(false)}
          />
        ) : null}
        <div className={styles.apartmentImage}>
          {apartment.media && apartment.media.length ? (
            <ImageGallery
              id={apartment.id}
              slideShowIndex={slideShowIndex}
              setSlideShowIndex={setSlideShowIndex}
              handleSlideTap={handleGalleryClick}
              media={apartment.media}
            />
          ) : (
            <div style={{ backgroundImage: `url(${t('apartmentPlaceholder')})` }} className={styles.placeHolder} />
          )}
        </div>
        <ApartmentInformationLocation apartment={apartment} />
      </div>
      {apartment?.isExclusive && (
        <div className={styles.informationSection} style={{ marginTop: 16 }}>
          <InfoBox text={'Diese Anzeige ist exklusiv für'}>
            <img src={'/assets/icon/pro.svg'} alt={'pro'} onClick={() => setShowSubscriptionDrawer(true)} />
          </InfoBox>
        </div>
      )}
      <div className={styles.informationSection} style={{ marginTop: 16 }}>
        <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
          {t('basics')}
        </Headline>
        <ApartmentBasics apartment={apartment} />
      </div>
      {customEquipmentDescription?.length > 10 || apartment.equipment?.equipmentList?.length ? (
        <div className={styles.informationSection}>
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            {t('equipment')}
          </Headline>
          {apartment.equipment?.equipmentList?.length ? (
            <ApartmentEquipment equipmentList={apartment.equipment.equipmentList} />
          ) : null}
          {customEquipmentDescriptionLength ? (
            <button
              onClick={() =>
                openModal({
                  id: MODAL_IDENTIFIER.INFO_TEXT,
                  data: {
                    title: t('apartment.equipment.description.label'),
                    sanitizeText: customEquipmentDescription,
                  },
                })
              }
            >
              <Text bold color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
                {`${t('showMore')} >`}
              </Text>
            </button>
          ) : null}
        </div>
      ) : null}
      {apartment.matterportId ? (
        <div className={styles.informationSection}>
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            {t('3dTour')}
          </Headline>
          <button
            onClick={() =>
              openModal({ id: MODAL_IDENTIFIER.VIRTUAL_TOUR, data: { matterportId: apartment.matterportId } })
            }
          >
            <div style={{ pointerEvents: 'none', borderRadius: '10px', overflow: 'hidden' }}>
              <VirtualTour matterportId={apartment.matterportId} />
            </div>
          </button>
        </div>
      ) : null}
      {apartment?.virtualTourLink && apartment?.virtualTourLink?.length ? (
        <div className={styles.informationSection}>
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            {t('3dTour')}
          </Headline>
          <button
            onClick={() =>
              openModal({ id: MODAL_IDENTIFIER.VIRTUAL_TOUR, data: { virtualTourLink: apartment.virtualTourLink } })
            }
          >
            <div style={{ pointerEvents: 'none', borderRadius: '10px', overflow: 'hidden' }}>
              <VirtualTour virtualTourLink={apartment.virtualTourLink} />
            </div>
          </button>
        </div>
      ) : null}
      {apartment?.layout && apartment?.layout?.length ? (
        <>
          <div className={styles.informationSection}>
            <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
              {t('layout')}
            </Headline>
            <div className={styles.layout}>
              {apartment?.layout?.map((layout) => {
                return (
                  <div onClick={() => window.open(layout?.url)} key={layout?.id} className={styles.layoutLink}>
                    <Text tag={'span'} align={'center'}>
                      {layout?.alt?.length > 40 ? `${layout?.alt.substring(0, 35)}...` : layout?.alt}
                    </Text>
                    <span className={styles.download}>
                      <FontAwesomeIcon icon={faDownload} />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : null}
      {costs && Object.keys(costs)?.length ? (
        <div className={styles.informationSection}>
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            {t('apartment.cost.title')}
          </Headline>
          <ApartmentCosts
            isSalesObject={apartment.mainInformation.typeOfMarketing === APARTMENT_TYPE_OF_MARKETING.SALE}
            {...costs}
          />
          {apartment?.cost?.freeTextPrice ? (
            <button
              onClick={() =>
                openModal({
                  id: MODAL_IDENTIFIER.INFO_TEXT,
                  data: {
                    title: t('apartment.cost.freeTextPrice.label'),
                    sanitizeText: freeTextPriceSanitized,
                  },
                })
              }
            >
              <Text>{truncateText(freeTextPriceSanitized, 80)}</Text>
              <Text bold color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
                {`${t('showMore')} >`}
              </Text>
            </button>
          ) : null}
        </div>
      ) : null}
      {apartment?.mainInformation?.typeOfMarketing === APARTMENT_TYPE_OF_MARKETING.RENT ? (
        <div className={styles.informationSection}>
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            {t('rent')}
          </Headline>
          <ApartmentRent mainInfo={apartment.mainInformation} aptRentType={apartment.cost?.rentType} />
        </div>
      ) : null}
      <ApartmentAreas areas={apartment.areas} />
      <ApartmentRooms areas={apartment.areas} />
      <ApartmentLocationMap apartment={apartment} setMapIsOpen={setMapIsOpen} />
      {apartment.energySupply && Object.keys(apartment.energySupply)?.length ? (
        <div className={styles.informationSection}>
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            {t('buildingAndLocation')}
          </Headline>
          <ApartmentEnergySupply energySupply={apartment.energySupply} />
          {customEnergyDescription ? (
            <button
              onClick={() =>
                openModal({
                  id: MODAL_IDENTIFIER.INFO_TEXT,
                  data: {
                    title: t('apartment.energySupply.condition.label'),
                    sanitizeText: customEnergyDescription,
                  },
                })
              }
            >
              <Text bold color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
                {`${t('showMore')} >`}
              </Text>
            </button>
          ) : null}
        </div>
      ) : null}
      {apartment?.documents && apartment?.documents?.length ? (
        <>
          <div className={styles.informationSection}>
            <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
              {t('additionalDocuments')}
            </Headline>
            <div className={styles.layout}>
              {apartment?.documents?.map((document) => {
                return (
                  <div onClick={() => window.open(document?.url)} key={document?.id} className={styles.layoutLink}>
                    <Text tag={'span'} align={'center'}>
                      {document?.alt?.length > 40 ? `${document?.alt.substring(0, 35)}...` : document?.alt}
                    </Text>
                    <span className={styles.download}>
                      <FontAwesomeIcon icon={faDownload} />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : null}
      {apartment?.links && apartment?.links?.length ? (
        <>
          <div className={styles.informationSection}>
            <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
              {t('additionalLinks')}
            </Headline>
            <div className={styles.layout}>
              {apartment?.links?.map((link, i) => {
                return (
                  <Link
                    key={`${link?.alt}-${i}`}
                    className={styles.link}
                    to={validateLink(link?.url)}
                    target={'_blank'}
                    rel={'noreferrer noopener'}
                    title={link?.alt}
                  >
                    <Text tag={'span'} align={'center'}>
                      {link?.alt?.length > 40 ? `${link?.alt.substring(0, 35)}...` : link?.alt}
                    </Text>
                    <span className={styles.download}>
                      <FontAwesomeIcon icon={faLink} />
                    </span>
                  </Link>
                );
              })}
            </div>
          </div>
        </>
      ) : null}
      {customOtherDescriptionSanitized ? (
        <div className={styles.informationSection}>
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            {t('Zusätzliche Informationen')}
          </Headline>
          <Text>{truncateText(customOtherDescriptionSanitized, 80)}</Text>
          <button
            onClick={() =>
              openModal({
                id: MODAL_IDENTIFIER.INFO_TEXT,
                data: {
                  title: t('Zusätzliche Informationen'),
                  sanitizeText: customOtherDescription,
                },
              })
            }
          >
            <Text bold color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
              {`${t('Mehr anzeigen')} >`}
            </Text>
          </button>
        </div>
      ) : null}
      {!!customLocationDescriptionSanitized ? (
        <div className={styles.informationSection}>
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            {t('Lage')}
          </Headline>
          <Text>{truncateText(customLocationDescriptionSanitized, 80)}</Text>
          <button
            onClick={() =>
              openModal({
                id: MODAL_IDENTIFIER.INFO_TEXT,
                data: {
                  title: t('Lage'),
                  sanitizeText: customLocationDescription,
                },
              })
            }
          >
            <Text bold color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
              {`${t('Mehr anzeigen')} >`}
            </Text>
          </button>
        </div>
      ) : null}
      {!!customGenerellDescriptionSanitized ? (
        <div className={styles.informationSection}>
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            {t('Objektbeschreibung')}
          </Headline>
          <Text>{truncateText(customGenerellDescriptionSanitized, 80)}</Text>
          <button
            onClick={() =>
              openModal({
                id: MODAL_IDENTIFIER.INFO_TEXT,
                data: {
                  title: 'Objektbeschreibung',
                  sanitizeText: customGenerellDescription,
                },
              })
            }
          >
            <Text bold color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
              {`${t('Mehr anzeigen')} >`}
            </Text>
          </button>
        </div>
      ) : null}
      {apartment?.contactPerson ? (
        <div className={styles.informationSection}>
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            {t('provider')}
          </Headline>
          <div>
            <Tag
              element={'span'}
              tag={{
                key: 'verified',
                label: `✅ ${t('verified')}`,
              }}
            />
          </div>
          <ApartmentContactPersonCard contactPerson={apartment.contactPerson} />
          <ApartmentOrganizationCard organization={organization} />
        </div>
      ) : null}
    </div>
  );
};

export default ApartmentInformation;
