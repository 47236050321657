import styles from './Avatar.module.scss';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

export enum AVATAR_SIZE {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  auto = 'auto',
}

export interface IAvatarProps {
  size?: AVATAR_SIZE;
  avatarUrl?: string;
  bordered?: boolean;
  onClick?: () => void;
}

const Avatar: FC<IAvatarProps> = ({ size = AVATAR_SIZE.md, avatarUrl, bordered = false, onClick }) => {
  const { t: c } = useTranslation('common');

  return (
    <div
      onClick={onClick ?? undefined}
      role="img"
      aria-label={c('profile.image')}
      className={`
      ${styles.avatar} 
      ${styles[size]}
      ${!!!avatarUrl ? styles.silhouette : ''} 
      ${bordered ? styles.border : ''} 
      ${onClick ? styles.onClick : ''}
      `}
      style={{ backgroundImage: avatarUrl ? `url('${avatarUrl}')` : 'none' }}
    >
      {!!!avatarUrl && <FontAwesomeIcon icon={faUser} />}
    </div>
  );
};

export default Avatar;
