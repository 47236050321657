import styles from './ApplicantInformationModal.module.scss';
import { FC } from 'react';

import useApplication from '../../../core/hook/application.hook';
import { ITenantProfile } from '@wohnsinn/ws-ts-lib';
import ApplicantSelfDisclosure from 'component/organisms/ApplicantSelfDisclosure';

const ApplicantInformationModal: FC<{
  apartmentId?: string;
  onClose: () => void;
  tenantProfile: ITenantProfile;
  landlordId?: string;
  showHeader?: boolean;
}> = ({ onClose, tenantProfile, landlordId, apartmentId, showHeader }) => {
  const { applicationList, loadingApplication } = useApplication(apartmentId, tenantProfile.uid, landlordId);

  if (landlordId && loadingApplication) {
    return <div>lädt application daten</div>;
  }

  return (
    <div className={styles.wrapper}>
      <ApplicantSelfDisclosure
        onClose={onClose}
        showHeader={showHeader}
        tenantProfile={tenantProfile}
        application={applicationList[0]}
      />
    </div>
  );
};

export default ApplicantInformationModal;
