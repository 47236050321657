import { FC, useContext } from 'react';
import { Grid, Switch } from '@mui/material';
import Headline, { HEADLINE_SIZE } from '../../atoms/typographie/Headline';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_VARIANT } from '../../atoms/typographie/Text';
import { wohnsinnServices } from '../../../App';
import UserContext from '../../../core/context/user.context';
import { useQuery } from '@tanstack/react-query';
import { createEquipmentList } from '../../molecules/ApartmentEquipment';
import { useTranslation } from 'react-i18next';
import TagList from '../../molecules/TagList';
import { createUrlFromTenantSearch } from '../../../core/helper/create-url-from-tenant-search';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import CTAButton, { BUTTON_STYLE } from '../../atoms/Buttons/CTAButton';
import styles from './SearchProfiles.module.scss';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import ModalContext from '../../../core/context/modal.context';
import { ITenantSearch } from '@wohnsinn/ws-ts-lib';
import CTACard from '../../molecules/Cards/CTACard';
import { ROUTES } from '../../../core/const/routes';
import PageTitle from '../../molecules/PageTitle';

const SearchProfiles: FC<{ showTitle?: boolean; onClick?: any }> = ({ showTitle = true, onClick }) => {
  const { tenantService } = wohnsinnServices;
  const { openModal } = useContext(ModalContext);
  const { tenantProfile } = useContext(UserContext);
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { t: e } = useTranslation('common', { keyPrefix: 'apartment.equipment.equipmentList' });

  const { data, isError, isLoading, refetch } = useQuery({
    queryKey: ['fetchTenantProfile', tenantProfile?.uid],
    queryFn: () => tenantService.getTenantSearchProfiles(tenantProfile.uid),
    staleTime: 1,
  });

  if (isError) {
    return <div>Fehler beim holen der Search Profiles</div>;
  }

  function Loading() {
    return <h2>🌀 Loading...</h2>;
  }

  if (isLoading) {
    return <Loading />;
  }

  const updateNotificationHandler = async (searchProfile: ITenantSearch) => {
    const settings = searchProfile.notificationSettings;
    settings.isMailEnabled = !searchProfile.notificationSettings.isMailEnabled;
    await tenantService.updateTenantSearchProfile(tenantProfile.uid, searchProfile.id, {
      ...searchProfile,
      notificationSettings: settings,
    });
    await refetch();
  };

  return (
    <Grid container spacing={4}>
      {showTitle && !!data.length && (
        <Grid item xs={12} md={10} xl={7}>
          <PageTitle pageTitle={r(ROUTES.tenantRoutes.searchProfiles.title)} />
        </Grid>
      )}
      {data.map((searchProfile: ITenantSearch) => {
        return (
          <Grid key={searchProfile.id} item xs={12}>
            <div className={styles.card}>
              <div className={styles.header}>
                <CTAButton
                  icon={faTrash}
                  buttonStyle={BUTTON_STYLE.DANGER_INVERTED}
                  buttonText={'Löschen'}
                  hideText
                  color={TEXT_COLOR.TEXT_COLOR_DANGER}
                  size={'small'}
                  onClick={() =>
                    openModal({
                      id: MODAL_IDENTIFIER.DELETE_MODAL,
                      data: {
                        title: 'Suchprofil löschen?',
                        text: 'Bist du dir sicher dass du diesen Suchauftrag aus deinem Profil löschen möchtest?',
                        deleteHandler: async () => {
                          await tenantService.deleteTenantSearch(tenantProfile.uid, searchProfile.id);
                          await refetch();
                        },
                      },
                    })
                  }
                />
              </div>

              <div className={styles.body}>
                <Headline size={HEADLINE_SIZE.H2}>
                  {searchProfile.location ? `Wohnungen in ${searchProfile.location.place_name}` : 'Ort nicht angegeben'}
                </Headline>

                <div className={styles.notificationSettings}>
                  <Text tag={'span'}>Benachrichtigungen:</Text>
                  <Switch
                    value={'value'}
                    onClick={() => updateNotificationHandler(searchProfile)}
                    checked={!!searchProfile?.notificationSettings?.isMailEnabled}
                  />
                </div>

                <div className={styles.infos}>
                  {searchProfile.size && <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>Ab {searchProfile.size} qm.</Text>}
                  {searchProfile.rooms && (
                    <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>Ab {searchProfile.rooms} Zimmer</Text>
                  )}
                  {searchProfile.numberOfPeopleMovingIn && (
                    <Text
                      type={TEXT_TYPE.TEXT_TYPE_BODY}
                      variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
                      color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                    >
                      {searchProfile.numberOfPeopleMovingIn} Personen ziehen ein
                    </Text>
                  )}
                  {searchProfile.warmRent && (
                    <Text
                      type={TEXT_TYPE.TEXT_TYPE_BODY}
                      variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
                      color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                    >
                      max {searchProfile.warmRent} € Warmmiete
                    </Text>
                  )}
                  {searchProfile.arePetsAllowed !== undefined && (
                    <Text
                      type={TEXT_TYPE.TEXT_TYPE_BODY}
                      variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
                      color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                    >
                      Haustiere ziehen mit ein: {searchProfile.arePetsAllowed ? 'Ja' : 'Nein'}
                    </Text>
                  )}
                  {searchProfile.isSharedUsagePossible !== undefined && (
                    <Text
                      type={TEXT_TYPE.TEXT_TYPE_BODY}
                      variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
                      color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                    >
                      WG-Geeignet: {searchProfile.isSharedUsagePossible ? 'Ja' : 'Nein'}
                    </Text>
                  )}
                  {searchProfile.equipmentList && searchProfile.equipmentList.length && (
                    <TagList testId={'apartmentEquipment'} tags={createEquipmentList(searchProfile.equipmentList, e)} />
                  )}
                </div>
              </div>
              <CTAButton
                rounded={false}
                buttonText={'Anzeigen'}
                link={createUrlFromTenantSearch(searchProfile)}
                onClick={onClick ? onClick : null}
              />
            </div>
          </Grid>
        );
      })}

      {!data.length && (
        <Grid item xs={12}>
          <CTACard
            imgSrc={'/assets/gif/notification.gif'}
            title={'Keine Suche gespeichert'}
            text={'Du hast bisher keine Suchen gespeichert. Starte eine neue Suche.'}
            imgAltText={'Ich will benachrichtigt werden'}
            ctaText={t('startSearch')}
            link={r(ROUTES.staticRoutes.search.path)}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SearchProfiles;
