import { FC, useContext, useState } from 'react';
import PageLayout from '../../../../component/layouts/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import CompletedFormCheck from '../../../../component/atoms/CompletedFormCheck';
import UserContext from '../../../../core/context/user.context';
import useTenantDocuments from '../../../../core/hook/tenant-document.hook';
import InfoBox, { INFO_BOX_ICONS } from '../../../../component/atoms/InfoBox';
import styles from './AccountOverviewView.module.scss';
import { BADGE_COLORS } from '../../../../component/atoms/Badge';

import ActionBanner from '../../../../component/atoms/ActionBanner';
import { wohnsinnServices } from '../../../../App';
import { Grid } from '@mui/material';
import SnackBarContext from '../../../../core/context/snackbar.context';
import PageTitle from '../../../../component/molecules/PageTitle';
import {
  getTenantApplicationState,
  TENANT_APPLICATION_STATE,
} from '../../../../core/helper/get-tenant-application-state';

const AccountOverviewView: FC = () => {
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { t: f } = useTranslation('common', { keyPrefix: 'view.AccountOverviewView' });
  const { firebaseAuthService } = wohnsinnServices;
  const { user, tenantProfile, activeUserType } = useContext(UserContext);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { hasIncomeProofDocument, hasSchufaCheckDocument } = useTenantDocuments(tenantProfile?.uid);
  const [isSendingVerificationMail, setIsSendingVerificationMail] = useState(false);

  const resendVerificationMail = async (): Promise<void> => {
    setIsSendingVerificationMail(true);
    try {
      await firebaseAuthService.triggerSendVerificationLink(tenantProfile.email, activeUserType);
      handleSnackBar('toast.resendEmailVerificationMail', 'info');
    } catch (e) {
      console.error('Error on resendVerificationMail', e);
    }
    setIsSendingVerificationMail(false);
  };
  const tenantApplicationState = getTenantApplicationState(tenantProfile);
  return (
    <>
      <PageLayout showPageTitle={false} pageTitle={r(ROUTES.tenantRoutes.account.overview.title)}>
        {!user.isEmailVerified ? (
          <>
            <div className={styles.banner}>
              <ActionBanner
                isLoading={isSendingVerificationMail}
                label={t('actionBanner.verifyYourEmail.label')}
                buttonText={t('actionBanner.verifyYourEmail.buttonText')}
                onClick={resendVerificationMail}
              />
            </div>
            <div className={styles.bannerSpace} />
          </>
        ) : null}

        <Grid container justifyContent={'center'} rowSpacing={2}>
          <Grid item xs={12}>
            <PageTitle
              showSubmitButton={false}
              pageTitle={r(ROUTES.tenantRoutes.account.overview.title)}
              badgeText={
                tenantApplicationState === TENANT_APPLICATION_STATE.COMPLETE
                  ? t('view.AccountOverviewView.Badge.accountComplete')
                  : t('view.AccountOverviewView.Badge.accountInComplete')
              }
              badgeColor={
                tenantApplicationState === TENANT_APPLICATION_STATE.COMPLETE
                  ? BADGE_COLORS.SUCCESS
                  : BADGE_COLORS.DANGER
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CompletedFormCheck
              pathname={r(ROUTES.tenantRoutes.account.profile.path)}
              status={!!tenantProfile?.personalInformation && !!tenantProfile.photoUrl}
              positiveText={t('completed')}
              negativeText={t('complete')}
              text={r(ROUTES.tenantRoutes.account.profile.title)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CompletedFormCheck
              pathname={r(ROUTES.tenantRoutes.account.household.path)}
              status={!!tenantProfile?.household}
              positiveText={t('completed')}
              negativeText={t('complete')}
              text={r(ROUTES.tenantRoutes.account.household.title)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CompletedFormCheck
              badge={f('recommended')}
              pathname={r(ROUTES.tenantRoutes.account.introductionVideo.path)}
              status={!!tenantProfile?.aboutMe?.introductionVideo}
              positiveText={t('uploaded')}
              negativeText={t('upload')}
              text={r(ROUTES.tenantRoutes.account.introductionVideo.title)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CompletedFormCheck
              pathname={r(ROUTES.tenantRoutes.account.aboutMe.path)}
              status={!!tenantProfile?.aboutMe?.description}
              positiveText={t('completed')}
              negativeText={t('complete')}
              text={r(ROUTES.tenantRoutes.account.aboutMe.title)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CompletedFormCheck
              pathname={r(ROUTES.tenantRoutes.account.schufa.path)}
              status={hasSchufaCheckDocument}
              positiveText={t('uploaded')}
              negativeText={t('upload')}
              text={r(ROUTES.tenantRoutes.account.schufa.title)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CompletedFormCheck
              pathname={r(ROUTES.tenantRoutes.account.incomeProof.path)}
              status={hasIncomeProofDocument}
              positiveText={t('uploaded')}
              negativeText={t('upload')}
              text={r(ROUTES.tenantRoutes.account.incomeProof.title)}
            />
          </Grid>
          {tenantApplicationState === TENANT_APPLICATION_STATE.COMPLETE ? null : (
            <Grid item xs={12}>
              <InfoBox text={f('infoApplicationFolderIncomplete')} icon={INFO_BOX_ICONS.LIGHT_BULB} />
            </Grid>
          )}
        </Grid>
      </PageLayout>
    </>
  );
};

export default AccountOverviewView;
