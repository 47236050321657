import ApplicantInformationModal from 'component/organisms/ApplicantInformationModal';
import LightShow from 'component/molecules/LightShow';
import PageLayout from 'component/layouts/PageLayout';
import UserContext from 'core/context/user.context';
import useApartment from 'core/hook/apartment.hook';
import { Drawer, Grid } from '@mui/material';
import { IApplication, IMedia, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import { useParams } from 'react-router-dom';
import { ROUTES } from 'core/const/routes';
import { onSnapshot } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { wohnsinnServices } from 'App';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import ModalContext from 'core/context/modal.context';
import ApplicationModal from 'component/organisms/ApplicationModal';
import Chat from 'component/organisms/Chat';
import ApplicantChatHeader from 'component/organisms/ApplicantChatHeader';
import DrawerWrapper from 'component/molecules/DrawerWrapper';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import { faBarsFilter, faTimes } from '@fortawesome/pro-solid-svg-icons';
import ApplicationFilter from './ApplicationFilter';
import LoadingAnimation from 'component/atoms/LoadingAnimation';
import ApplicantsTable from 'component/organisms/Tables/ApplicantsTable';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import styles from './ApartmentApplicationsView.module.scss';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';

export interface IRange {
  low: number;
  high: number;
}

export interface APPLIED_FILTER {
  ageRange: IRange;
  hasPledge: boolean;
  numberOfPeopleMovingIn: number;
  petsForbidden: boolean;
  sharedUsageForbidden: boolean;
  hasIncomeProof: boolean;
  hasSchufa: boolean;
  hasIntroductionVideo: boolean;
  jobs: {
    NONE: boolean;
    SELF_EMPLOYED: boolean;
    STUDENT: boolean;
    EMPLOYED: boolean;
    TRAINEE: boolean;
    CUSTOM: boolean;
    FREELANCER: boolean;
    PHD_STUDENT: boolean;
    SEEKING: boolean;
    HOUSEKEEPER: boolean;
    RENT: boolean;
    OFFICIAL: boolean;
  };
}

const INITIAL_FILTER_STATE: APPLIED_FILTER = {
  hasPledge: false,
  hasIncomeProof: false,
  hasIntroductionVideo: false,
  hasSchufa: false,
  numberOfPeopleMovingIn: null,
  petsForbidden: false,
  sharedUsageForbidden: false,
  jobs: {
    NONE: false,
    SELF_EMPLOYED: false,
    STUDENT: false,
    EMPLOYED: false,
    TRAINEE: false,
    CUSTOM: false,
    FREELANCER: false,
    PHD_STUDENT: false,
    SEEKING: false,
    HOUSEKEEPER: false,
    RENT: false,
    OFFICIAL: false,
  },
  ageRange: { low: 0, high: 100 },
};

const ApartmentApplicationsView = () => {
  // Translations
  const { t: r } = useTranslation('routes');
  const { t: a } = useTranslation('common', { keyPrefix: 'view.ApplicationOverviewView' });
  const { apartmentId } = useParams<{ apartmentId: string }>();
  const { apartment } = useApartment(apartmentId);
  // Contexts
  const { openModal } = useContext(ModalContext);
  const { user } = useContext(UserContext);
  const { applicationService, mixpanelTrackingService, chatService } = wohnsinnServices;
  // States
  const [isLightShowOpen, setIsLightShowOpen] = useState<boolean>(false);
  const [introductionVideo, setIntroductionVideo] = useState<IMedia>(null);
  const [applicationModalIsOpen, setApplicationModalIsOpen] = useState<boolean>(false);
  const [filterMenuIsOpen, setFilterMenuIsOpen] = useState<boolean>(false);
  const [showApplicantInformation, setShowApplicantInformation] = useState<boolean>(false);
  const [showApplicantChat, setShowApplicantChat] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState<IApplication>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [likeApplications, setLikeApplications] = useState<IApplication[]>([]);
  const [maybeApplications, setMaybeApplications] = useState<IApplication[]>([]);
  const [nopeApplications, setNopeApplications] = useState<IApplication[]>([]);
  const [unsortedApplications, setUnsortedApplications] = useState<IApplication[]>([]);
  const [selectedApplicationList, setSelectedApplicationList] = useState<IApplication[]>([]);
  const [appliedFilter, setAppliedFilter] = useState<APPLIED_FILTER>(INITIAL_FILTER_STATE);

  const fetchApplications = async () => {
    setIsLoading(true);
    onSnapshot(
      applicationService.getLandlordApplicationListRef({
        isAdmin: user?.isAdmin,
        landlordId: user.uid,
        apartmentId: apartmentId,
        tenantId: null,
        rating: MATCHING_MODE.LIKE,
      }),
      (snapshot) => {
        const applications = snapshot.docs.map((doc) => doc.data()) as IApplication[];
        setLikeApplications(applications);
      }
    );
    onSnapshot(
      applicationService.getLandlordApplicationListRef({
        isAdmin: user?.isAdmin,
        landlordId: user.uid,
        apartmentId: apartmentId,
        tenantId: null,
        rating: MATCHING_MODE.MAYBE,
      }),
      (snapshot) => {
        const applications = snapshot.docs.map((doc) => doc.data()) as IApplication[];
        setMaybeApplications(applications);
      }
    );
    onSnapshot(
      applicationService.getLandlordApplicationListRef({
        isAdmin: user?.isAdmin,
        landlordId: user.uid,
        apartmentId: apartmentId,
        tenantId: null,
        rating: MATCHING_MODE.NOPE,
      }),
      (snapshot) => {
        const applications = snapshot.docs.map((doc) => doc.data()) as IApplication[];
        setNopeApplications(applications);
      }
    );
    onSnapshot(
      applicationService.getLandlordApplicationListRef({
        isAdmin: user?.isAdmin,
        landlordId: user.uid,
        apartmentId: apartmentId,
        tenantId: null,
        rating: MATCHING_MODE.NONE,
      }),
      (snapshot) => {
        const applications = snapshot.docs.map((doc) => doc.data()) as IApplication[];
        setUnsortedApplications(applications);
      }
    );
    setIsLoading(false);
  };

  useEffect(() => {
    fetchApplications();
  }, [apartmentId]);

  const handleClickOnApplicantInfo = async (application: IApplication) => {
    setSelectedApplication(application);
    mixpanelTrackingService.trackEvent('OpenedChatMessageAsLandlord', {
      tenantId: application.tenantProfile.uid,
      tenantName: `${application.tenantProfile.personalInformation.firstName} ${application.tenantProfile.personalInformation.lastName}`,
    });

    await chatService.clearUnreadTenantMessagesRefOnApplication(
      application.landlordId,
      application.apartmentId,
      application.tenantProfile.uid
    );

    await chatService.clearUnreadTenantChatsRefOnApartment(
      application.apartmentId,
      application.landlordId,
      application.tenantProfile.uid
    );

    setApplicationModalIsOpen(true);
  };

  const handleClickOnChat = async (application: IApplication) => {
    setSelectedApplication(application);

    await chatService.clearUnreadTenantMessagesRefOnApplication(
      application.landlordId,
      application.apartmentId,
      application.tenantProfile.uid
    );

    await chatService.clearUnreadTenantChatsRefOnApartment(
      application.apartmentId,
      application.landlordId,
      application.tenantProfile.uid
    );

    setShowApplicantChat(true);
  };

  const handleMultiMessageButtonClick = () => {
    openModal({ id: MODAL_IDENTIFIER.MULTI_MESSAGE, data: { applicationList: selectedApplicationList } });
  };

  const handleIntroductionVideoClick = (video: IMedia) => {
    setIntroductionVideo(video);
    setIsLightShowOpen(true);
  };

  const handleApplicantInformationClick = (application: IApplication) => {
    setSelectedApplication(application);
    setShowApplicantInformation(true);
  };

  if (!apartment) {
    return (
      <PageLayout pageTitle={r(ROUTES.landlordRoutes.apartment.applications.title)}>
        <h1>{a('loading')}</h1>
      </PageLayout>
    );
  }

  function countDifferentProperties(obj1: any, obj2: any) {
    let count = 0;

    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
          count += countDifferentProperties(obj1[key], obj2[key]);
        } else if (obj1[key] !== obj2[key]) {
          count++;
        }
      } else {
        count++;
      }
    }

    return count;
  }

  const APPLIED_FILTER_COUNT = countDifferentProperties(INITIAL_FILTER_STATE, appliedFilter);

  return (
    <PageLayout
      showPageTitle={true}
      showTitleBackButton={true}
      pageTitle={`${apartment?.mainInformation?.address.street} ${apartment?.mainInformation?.address.houseNumber}`}
      secondPageTitle={`${apartment?.mainInformation?.address?.postalCode} ${apartment?.mainInformation?.address?.city}`}
    >
      <Drawer anchor={'right'} open={filterMenuIsOpen} onClose={() => setFilterMenuIsOpen(false)}>
        <DrawerWrapper noTitleMargin onClose={() => setFilterMenuIsOpen(false)} title={'Filter'}>
          <ApplicationFilter
            setAppliedFilters={setAppliedFilter}
            appliedFilters={appliedFilter}
            onClose={() => setFilterMenuIsOpen(false)}
          />
        </DrawerWrapper>
      </Drawer>

      {selectedApplication ? (
        <>
          <ApplicationModal
            apartmentId={selectedApplication.apartmentId}
            tenantProfile={selectedApplication.tenantProfile}
            landlordId={selectedApplication.landlordId}
            application={selectedApplication}
            isOpen={applicationModalIsOpen}
            onClose={() => setApplicationModalIsOpen(false)}
          />
          <Drawer anchor={'right'} open={showApplicantInformation} onClose={() => setShowApplicantInformation(false)}>
            <ApplicantInformationModal
              showHeader
              apartmentId={apartmentId}
              tenantProfile={selectedApplication.tenantProfile}
              landlordId={selectedApplication.landlordId}
              onClose={() => setShowApplicantInformation(false)}
            />
          </Drawer>
          <Drawer anchor={'right'} open={showApplicantChat} onClose={() => setShowApplicantChat(false)}>
            <div className={styles.chatWrapper}>
              <Chat
                key={selectedApplication.apartmentId}
                header={
                  <ApplicantChatHeader onClose={() => setShowApplicantChat(false)} application={selectedApplication} />
                }
                application={selectedApplication}
              />
            </div>
          </Drawer>
        </>
      ) : null}
      {introductionVideo ? (
        <LightShow open={isLightShowOpen} onClose={() => setIsLightShowOpen(false)} media={[introductionVideo]} />
      ) : null}

      <div className={styles.filterBar}>
        <CTAButton
          disabled={selectedApplicationList.length <= 0}
          icon={faEnvelope}
          buttonText={'Massen-Nachricht senden'}
          onClick={handleMultiMessageButtonClick}
          rounded={false}
        />
        <CTAButton
          icon={faBarsFilter}
          buttonStyle={APPLIED_FILTER_COUNT > 0 ? BUTTON_STYLE.SECONDARY : BUTTON_STYLE.PRIMARY}
          buttonText={`Filter (${APPLIED_FILTER_COUNT})`}
          onClick={() => setFilterMenuIsOpen(true)}
          rounded={false}
        />
        {APPLIED_FILTER_COUNT > 0 ? (
          <CTAButton
            buttonStyle={BUTTON_STYLE.TERTIARY}
            color={TEXT_COLOR.TEXT_COLOR_DARK}
            icon={faTimes}
            buttonText={`Alle Filter zurücksetzen`}
            onClick={() => setAppliedFilter(INITIAL_FILTER_STATE)}
            rounded={false}
          />
        ) : null}
      </div>

      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <Grid item xs={12}>
          <ApplicantsTable
            tenantApplicationList={selectedApplicationList}
            setTenantApplicationList={setSelectedApplicationList}
            appliedFilter={appliedFilter}
            likeApplications={likeApplications}
            maybeApplications={maybeApplications}
            nopeApplications={nopeApplications}
            unsortedApplications={unsortedApplications}
            handleIntroductionVideoClick={handleIntroductionVideoClick}
            handleClickOnApplicantInfo={handleClickOnApplicantInfo}
            handleApplicantInformationClick={handleApplicantInformationClick}
            handleClickOnChat={handleClickOnChat}
          />
        </Grid>
      )}
    </PageLayout>
  );
};
export default ApartmentApplicationsView;
