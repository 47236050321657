import { Grid } from '@mui/material';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_VARIANT } from '../../../atoms/typographie/Text';
import { FC, PropsWithChildren } from 'react';
import Spacer, { SPACER_SIZE } from '../../../atoms/Spacer';
import CTAButton from '../../../atoms/Buttons/CTAButton';
import { faStar, faStarHalfStroke } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WistiaPlayer from 'component/atoms/WistiaPlayer';

const FirstSection: FC<
  PropsWithChildren<{
    ctaHref?: string;
    ctaLink?: string;
    ctaOnClick?: () => any;
    ctaText?: string;
    title: string;
    titleTwo?: string;
    videoId: string;
    thumbnailId: string;
  }>
> = ({ ctaHref, ctaLink, ctaOnClick, ctaText, title, videoId, thumbnailId, titleTwo = '' }) => {
  return (
    <Grid container paddingTop={{ xs: 4, md: 8 }} spacing={{ xs: 4, md: 2 }} justifyContent={'space-between'}>
      <Grid item xs={12} md={7}>
        <Grid container>
          <Grid item xs={12} md={11}>
            <Headline size={HEADLINE_SIZE.HERO}>{title}</Headline>
            <Spacer mobileSize={SPACER_SIZE.MD} />
          </Grid>
          <Grid item xs={12} md={10}>
            <Text type={TEXT_TYPE.TEXT_TYPE_LANDING} color={TEXT_COLOR.TEXT_COLOR_DARK}>
              {titleTwo}
            </Text>
            <Spacer mobileSize={SPACER_SIZE.MD} />
          </Grid>
          <Grid item xs={12} md={10}>
            {ctaLink || ctaOnClick || ctaHref ? (
              <CTAButton
                rounded={false}
                expandMobile={true}
                size={'extra'}
                buttonText={ctaText}
                href={ctaHref ?? undefined}
                link={ctaLink ?? undefined}
                onClick={ctaOnClick ?? undefined}
              />
            ) : null}
            <div>
              <Spacer mobileSize={SPACER_SIZE.MD} />
              <Text type={TEXT_TYPE.TEXT_TYPE_LANDING} color={TEXT_COLOR.TEXT_COLOR_DARK}>
                {'4,8 '}
                <FontAwesomeIcon icon={faStar} color={'#f2ca26'}></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color={'#f2ca26'}></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color={'#f2ca26'}></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color={'#f2ca26'}></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStarHalfStroke} color={'#f2ca26'}></FontAwesomeIcon>
              </Text>
              <Text
                type={TEXT_TYPE.TEXT_TYPE_LANDING}
                color={TEXT_COLOR.TEXT_COLOR_DARK}
                variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
              >
                {'Google-Rezensionen'}
              </Text>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={5}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <WistiaPlayer videoId={videoId} wrapper={'testid'} thumbnailId={thumbnailId} />
            <Spacer />
            <Text
              type={TEXT_TYPE.TEXT_TYPE_LANDING}
              mobileAlign={'center'}
              color={TEXT_COLOR.TEXT_COLOR_DARK}
              variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
              bold
            >
              {'Unsere Kooperationspartner:'}
            </Text>
            <Spacer mobileSize={SPACER_SIZE.MD} />
            <Grid container flexDirection={'row'} display={'flex'} spacing={2}>
              <Grid item xs={6}>
                <img
                  width={'100%'}
                  src={'/assets/icon/partner-logos/hausUndGrundBonn.svg'}
                  alt="Grafik mit 3 Personen die auf einem Handy eine Wohnung suchen"
                />
              </Grid>
              <Grid item xs={6}>
                <img
                  width={'100%'}
                  src={'/assets/icon/partner-logos/nrwBank.svg'}
                  alt="Grafik mit 3 Personen die auf einem Handy eine Wohnung suchen"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FirstSection;
