import { FC } from 'react';
import ListWithIcons, { IListWithIconItem } from '../../../molecules/ListWithIcons';
import { faSparkles, faPhoneRotary, faAt } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { ITenantProfile } from '@wohnsinn/ws-ts-lib';

const TenantGeneralList: FC<{ tenantProfile: ITenantProfile }> = ({ tenantProfile }) => {
  const { t } = useTranslation('common');
  const generalInfo: IListWithIconItem[] = [];

  if (tenantProfile?.createdAt) {
    generalInfo.push({
      icon: faSparkles,
      text: t('sinceAtWohnsinn', { year: tenantProfile?.createdAt?.getFullYear() }),
    });
  }

  if (tenantProfile?.email) {
    generalInfo.push({
      icon: faAt,
      text: tenantProfile?.email,
    });
  }

  if (tenantProfile?.personalInformation?.phoneNumber) {
    generalInfo.push({
      icon: faPhoneRotary,
      text: tenantProfile?.personalInformation?.phoneNumber,
    });
  }

  if (tenantProfile?.personalInformation) {
    return (
      <div className={'ion-margin-top ion-padding-bottom'}>
        <ListWithIcons list={generalInfo} />
      </div>
    );
  }

  return null;
};

export default TenantGeneralList;
