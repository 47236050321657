import CTAButton from 'component/atoms/Buttons/CTAButton';
import FilterMenu from 'component/organisms/FilterMenu';
import ModalContext from 'core/context/modal.context';
import TenantFilterParamsContext from 'core/context/tenant-filter-params.context';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_VARIANT } from 'component/atoms/typographie/Text';
import styles from './FilterBar.module.scss';
import useWindowSize from 'core/hook/windowsize.hook';
import { Dispatch, FC, SetStateAction, useContext, useState } from 'react';
import { Drawer } from '@mui/material';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import { faSlidersUp } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export enum FILTER_TABS {
  LOCATION = 'LOCATION',
  NONE = 'NONE',
}

const FilterBar: FC<{ filterMenuOpen: boolean; setFilterMenuOpen: Dispatch<SetStateAction<boolean>> }> = ({
  filterMenuOpen,
  setFilterMenuOpen,
}) => {
  const { openModal } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const { tenantFilterParams } = useContext(TenantFilterParamsContext);
  const [activeTab, setActiveTab] = useState(FILTER_TABS.NONE);
  const { isSmallerMd } = useWindowSize();

  const handleClickOnFilter = (filter: FILTER_TABS) => {
    let filterId: MODAL_IDENTIFIER = null;
    switch (filter) {
      case FILTER_TABS.LOCATION:
        filterId = MODAL_IDENTIFIER.FILTER_LOCATION;
        setActiveTab(FILTER_TABS.LOCATION);
        break;
      default:
        filterId = null;
    }

    if (!filterId) return;
    openModal({ id: filterId, data: { setActiveTab } });
  };

  return (
    <div className={styles.filterBar}>
      <Drawer anchor={'right'} open={filterMenuOpen} onClose={() => setFilterMenuOpen(false)}>
        <FilterMenu onClose={() => setFilterMenuOpen(false)} />
      </Drawer>
      <div className={`${styles.wrapper} ${activeTab !== FILTER_TABS.NONE ? styles.active : ''}`}>
        <button
          onClick={() => handleClickOnFilter(FILTER_TABS.LOCATION)}
          className={`${styles.location} ${activeTab === FILTER_TABS.LOCATION ? styles.active : ''}`}
        >
          {tenantFilterParams?.location?.place_name ? (
            <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_CAPTION} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
              Wohin?
            </Text>
          ) : null}
          <Text
            tag={'span'}
            align={'left'}
            variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
            color={tenantFilterParams?.location?.place_name ? TEXT_COLOR.TEXT_COLOR_DARK : TEXT_COLOR.TEXT_COLOR_ACCENT}
            bold
          >
            {tenantFilterParams?.location?.place_name
              ? tenantFilterParams.location.place_name
              : 'Wo möchtest du wohnen?'}
          </Text>
        </button>

        <CTAButton
          customStyling={styles.filterButton}
          onClick={() => setFilterMenuOpen(true)}
          icon={faSlidersUp}
          hideText={isSmallerMd}
          buttonText={t('Filter')}
        />
      </div>
    </div>
  );
};

export default FilterBar;
