import styles from './Badge.module.scss';
import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Text, { TEXT_TYPE } from '../typographie/Text';

export enum BADGE_COLORS {
  PRIMARY = 'PRIMARY',
  DANGER = 'DANGER',
  LIGHT = 'LIGHT',
  SUCCESS = 'SUCCESS',
  DISABLED = 'DISABLED',
  DARK = 'DARK',
  MEDIUM = 'MEDIUM',
}

export interface IBadgeProps {
  text: string;
  color?: BADGE_COLORS;
  icon?: IconProp;
  action?: () => any;
  bold?: boolean;
  statusColor?: BADGE_COLORS;
}

const Badge: FC<IBadgeProps> = ({ text, color = BADGE_COLORS.PRIMARY, icon, action, bold = true, statusColor }) => {
  return (
    <div className={`${styles.badge} ${styles[color]} ${statusColor ? styles.withStatus : ''}`}>
      {statusColor ? <div className={`${styles.statusColor} ${styles[statusColor]}`} /> : null}
      {icon && action ? <FontAwesomeIcon className={styles.icon} icon={icon} onClick={action} /> : null}
      <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} className={styles[color]} bold={bold} tag={'span'}>
        {text}
      </Text>
    </div>
  );
};

export default Badge;
