import { FC, useContext, useEffect, useState } from 'react';
import FormSectionTitle from 'component/atoms/FormSectionTitle';
import InputText from 'component/atoms/formElement/InputText';
import styles from './LandlordOrganizationProfileForm.module.scss';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import { faCopy, faSpinner, faTrash } from '@fortawesome/pro-light-svg-icons';
import MediaUploadDropZone from 'component/molecules/MediaUploadDropZone';
import { FormContext } from 'core/context/form.context';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { FileRejection } from 'react-dropzone';
import { FIRESTORE_COLLECTION_PATH, IOrganization, LANDLORD_ROLES, ORGANIZATION_SCHEME } from '@wohnsinn/ws-ts-lib';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import UserContext from 'core/context/user.context';
import useOrganization from 'core/hook/organization.hook';
import InputWithDebounce from 'component/atoms/formElement/InputWithDebounce';
import FormHeader from 'component/atoms/FormHeader';
import FormFooter from 'component/atoms/FormFooter';
import SplitLine from 'component/atoms/SplitLine';
import Text, { TEXT_TYPE } from 'component/atoms/typographie/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROUTES } from 'core/const/routes';
import { wohnsinnServices } from 'App';
import SnackBarContext from 'core/context/snackbar.context';
import InfoBox from 'component/atoms/InfoBox';
import { ILandlordAccountFormProps } from 'view/landlord/AccountView';

export const DEFAULT_ORGANIZATION_DATA: IOrganization = {
  id: null,
  title: null,
  website: null,
  domain: null,
  photoUrl: null,
  imprintLink: null,
  imprintText: null,
  dataProtectionLink: null,
  dataProtectionText: null,
  address: {
    street: null,
    postalCode: null,
    houseNumber: null,
    city: null,
    coordinates: null,
    geoHash: null,
  },
  creatorId: null,
  FTPInformation: null,
  contactEmail: null,
  slug: null,
};

const LandlordOrganizationProfileForm: FC<ILandlordAccountFormProps> = ({ scrollToTop }) => {
  const { landlordProfile } = useContext(UserContext);
  const { organizationService, firebaseStorageService } = wohnsinnServices;
  const { organization } = useOrganization();
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { t: v } = useTranslation('common', { keyPrefix: 'view.OrganizationProfileView' });
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { handleSnackBar } = useContext(SnackBarContext);

  const isOrganizationAdmin =
    landlordProfile?.roles && landlordProfile?.roles?.includes(LANDLORD_ROLES.ORGANIZATION_ADMIN);

  const { control, watch, setValue, handleSubmit, reset } = useForm<IOrganization>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(ORGANIZATION_SCHEME)),
    defaultValues: organization ?? DEFAULT_ORGANIZATION_DATA,
  });

  const photoUrl = watch('photoUrl');

  useEffect(() => {
    if (organization) {
      reset(organization);
    } else {
      reset();
    }
  }, [organization]);

  const updateOrganization = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    scrollToTop();
    await organizationService.updateOrganization(watch() as IOrganization, landlordProfile);
    handleSnackBar('toast.profileUpdated', 'info');
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
  };

  const handleError = (err: any): void => {
    console.error(err);
  };

  const deleteOrganizationLogo = async () => {
    setValue('photoUrl', null);
    organizationService
      .updateOrganizationLogo(organization?.id, null)
      .catch(console.error)
      .then(() => {
        handleSnackBar(v('logo.deletedSuccessfully'), 'success');
      });
  };

  const copyInputValueToClipboard = (id: string): void => {
    const text = document.getElementById(id).innerHTML;
    navigator.clipboard
      .writeText(text)
      .then(() => handleSnackBar('view.AccountView.OpenImmoView.toast.copied', 'success'));
  };

  const onDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]): Promise<void> => {
    const path = `${FIRESTORE_COLLECTION_PATH.organizations.root}/${landlordProfile.organizationId}/${acceptedFiles[0].name}`;

    try {
      const downloadUrl = await firebaseStorageService.uploadBlob(
        acceptedFiles[0],
        path,
        landlordProfile.uid,
        acceptedFiles[0].type
      );

      await organizationService.updateOrganizationLogo(organization.id, downloadUrl);
      setValue('photoUrl', downloadUrl);
    } catch (e) {
      console.error(e);
    }

    if (rejectedFiles?.length) {
      // SEND TOAST
      console.error('no accepted files');
    }
  };

  if (!organization?.slug) {
    return (
      <>
        <FormHeader subtitle={'Ihre Firma wird gerade angelegt'} title={'Firmendaten'} />
        <FontAwesomeIcon spin={true} width={'23px'} height={'23px'} icon={faSpinner} size={'1x'} />{' '}
      </>
    );
  }

  if (isOrganizationAdmin) {
    return (
      <FormContext.Provider value={{ control }}>
        <form onSubmit={handleSubmit(updateOrganization, handleError)}>
          <FormHeader subtitle={'Hinterlege die Informationen für deine Firma.'} title={'Firmendaten'} />
          <FormSectionTitle title={v('organizationLogo')} />
          {photoUrl ? (
            <>
              <img className={styles.organizationImage} src={photoUrl} alt={`${organization?.title} Logo`} />
              <CTAButton
                icon={faTrash}
                buttonStyle={BUTTON_STYLE.SECONDARY}
                size={'small'}
                onClick={deleteOrganizationLogo}
                buttonText={v('logo.delete')}
              />
            </>
          ) : (
            <MediaUploadDropZone multiple={false} onDrop={onDrop} />
          )}
          {organization?.slug ? (
            <>
              <SplitLine />
              <FormSectionTitle title={v('portfolioLink')} />
              <div className={styles.copyButtonWrapper}>
                <Text id={'portfolioLink'} tag={'span'} type={TEXT_TYPE.TEXT_TYPE_BODY}>
                  {process.env.REACT_APP_BASE_URL}
                  {r(ROUTES.staticRoutes.organization.portfolio.path).replace(':slug', organization?.slug)}
                </Text>
                <button
                  className={styles.button}
                  type={'button'}
                  onClick={() => copyInputValueToClipboard('portfolioLink')}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              </div>
            </>
          ) : null}
          <SplitLine />
          <FormSectionTitle title={v('addressInformation')} />
          <InputWithDebounce
            debounceTimeout={300}
            label={t('organization.title.label')}
            name={'title'}
            required={true}
          />
          <InputText label={t('address.street.label')} name={'address.street'} required />
          <InputText label={t('address.houseNumber.label')} name={'address.houseNumber'} required />
          <InputText type={'text'} label={t('address.postalCode.label')} name={'address.postalCode'} required />
          <InputText type={'text'} label={t('address.city.label')} name={'address.city'} required />
          <FormSectionTitle title={v('legalInformation')} />
          <InputText label={t('organization.website.label')} name={'website'} />
          <InputText label={t('organization.imprintLink.label')} name={'imprintLink'} />
          <InputText label={t('organization.dataProtectionLink.label')} name={'dataProtectionLink'} />
          <FormSectionTitle title={t('organization.contactEmail.title')} />
          <InfoBox text={t('organization.contactEmail.info')} />

          <InputText label={t('organization.contactEmail.label')} name={'contactEmail'} />
          <FormFooter buttonSubmitMode={buttonSubmitMode} />
        </form>
      </FormContext.Provider>
    );
  }

  return (
    <div>
      <FormHeader subtitle={'Hinterlege die Informationen für deine Firma.'} title={'Firmendaten'} />
      <FormSectionTitle title={v('organizationLogo')} />

      {organization?.photoUrl ? (
        <img className={styles.organizationImage} src={organization?.photoUrl} alt={`${organization?.title} Logo`} />
      ) : null}

      <FormSectionTitle title={v('addressInformation')} />
      <Text>{organization?.title}</Text>
      <p>
        <Text tag={'span'}>{organization?.address?.street}</Text>
        <Text tag={'span'}>{organization?.address?.houseNumber}</Text>
      </p>
      <Text tag={'span'}>{organization?.address?.postalCode}</Text>
      <Text tag={'span'}>{organization?.address?.city}</Text>

      <FormSectionTitle title={v('legalInformation')} />
      <Text tag={'p'}>{organization?.website}</Text>
      <Text tag={'p'}>{organization?.dataProtectionLink}</Text>
      <Text tag={'p'}>{organization?.imprintLink}</Text>
    </div>
  );
};

export default LandlordOrganizationProfileForm;
