import { FC } from 'react';
import styles from './ListWithIcons.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';

export interface IListWithIconItem {
  text: string;
  icon: IconProp;
  iconAfter?: {
    icon: IconProp;
    color: string;
  };
}

const ListWithIcons: FC<{ list: IListWithIconItem[] }> = ({ list }) => {
  return (
    <ul className={styles.list}>
      <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
        {list.map((item, index) => (
          <li key={`${item.text} ${index}`}>
            <div className={styles.icon}>
              <FontAwesomeIcon icon={item.icon} />
            </div>
            <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
              <span>{item.text}</span>
              {item.iconAfter ? (
                <div className={`${styles.after}`}>
                  <FontAwesomeIcon icon={item.iconAfter.icon} color={item.iconAfter.color} />
                </div>
              ) : null}
            </Text>
          </li>
        ))}
      </Text>
    </ul>
  );
};

export default ListWithIcons;
