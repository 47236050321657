import { FC, useContext, useState } from 'react';
import UserContext from '../../../core/context/user.context';
import { APPLICATION_STATE, IApplication, TChatMessageCreate, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import styles from './Chat.module.scss';
import useChat from '../../../core/hook/chat.hook';
import { FieldErrorsImpl, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { ReactI18NextChild, useTranslation } from 'react-i18next';
import ChatMessageList from './ChatMessageList';
import TextEditor from '../../atoms/formElement/TextEditor';
import { FormContext } from '../../../core/context/form.context';
import { SUBMIT_BUTTON_MODE } from '../../../core/enum/submit-button-mode.enum';
import CTAButton from '../../atoms/Buttons/CTAButton';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import InfoBox from 'component/atoms/InfoBox';
import printDate from 'core/helper/printDate';

const Chat: FC<{ application: IApplication; header?: ReactI18NextChild; customStyling?: any }> = ({
  application,
  header,
  customStyling,
}) => {
  const { t } = useTranslation('common');
  const { activeUserType, tenantProfile, landlordProfile, user } = useContext(UserContext);
  const [submitButtonMode, setSubmitButtonMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);

  const { chatService, chatMessageList, isLoadingMessages, setIsTyping } = useChat(
    application.tenantProfile?.uid,
    application?.landlordId,
    application?.apartmentId
  );

  const { control, handleSubmit, watch, reset } = useForm<{ text: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object({ text: Joi.string().min(5) })),
  });

  const onError = (err: Partial<FieldErrorsImpl<{ text: string }>>): void => {
    console.error(err);
    setSubmitButtonMode(SUBMIT_BUTTON_MODE.ERROR);
  };

  const submitForm = async (data: any) => {
    setSubmitButtonMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    const landlordName =
      landlordProfile?.personalInformation?.firstName && landlordProfile?.personalInformation?.lastName
        ? `${landlordProfile?.personalInformation.firstName} ${landlordProfile?.personalInformation.lastName}`
        : t('landlord');
    const tenantName =
      tenantProfile?.personalInformation?.firstName && tenantProfile?.personalInformation?.lastName
        ? `${tenantProfile?.personalInformation.firstName} ${tenantProfile?.personalInformation.lastName}`
        : '';
    const senderName = activeUserType === USER_TYPE.LANDLORD ? landlordName : tenantName;

    if (user?.uid) {
      const message: TChatMessageCreate = {
        attachment: null,
        apartmentId: application.apartmentId,
        landlordId: application.landlordId,
        tenantId: application.tenantProfile.uid,
        senderId: user.uid,
        senderName,
        senderAvatar: tenantProfile ? tenantProfile.photoUrl : landlordProfile.photoUrl,
        text: data.text,
      };

      try {
        await chatService.sendMessage(application, message);
        setIsTyping(false);
        reset({ text: null });
        setSubmitButtonMode(SUBMIT_BUTTON_MODE.SUCCESS);
      } catch (e) {
        setSubmitButtonMode(SUBMIT_BUTTON_MODE.ERROR);
        console.error('error sending message', e);
      }
    }
  };
  const checkIfChatIsClosed = (): boolean => {
    // ICH BIN TENANT
    if (user.uid === application.tenantProfile.uid) {
      return checkIfChatWillBeDeleted();
    }

    return false;
  };

  const checkIfChatWillBeDeleted = (): boolean => {
    return (
      application.applicationState === APPLICATION_STATE.DELETED ||
      application.applicationState === APPLICATION_STATE.WILL_BE_DELETED
    );
  };

  const LoadingMessageListPlaceholder = () => (
    <div className={styles.loadingMessagesListPlaceholder}>
      <div className={`${styles.loadingMessage} ${styles.right}`} />
      <div className={`${styles.loadingMessage} ${styles.right}`} />
      <div className={`${styles.loadingMessage}`} />
      <div className={`${styles.loadingMessage}`} />
      <div className={`${styles.loadingMessage}`} />
      <div className={`${styles.loadingMessage} ${styles.right}`} />
      <div className={`${styles.loadingMessage} ${styles.right}`} />
      <div className={`${styles.loadingMessage}`} />
      <div className={`${styles.loadingMessage} ${styles.right}`} />
      <div className={`${styles.loadingMessage}`} />
      <div className="sr-only">loading chat</div>
    </div>
  );
  const LoadingChatInputPlaceholder = () => (
    <div className={styles.loadingChatInputPlaceholder}>
      <div className="sr-only">loading chat</div>
    </div>
  );

  return (
    <div className={`${styles.chatView} ${customStyling ? customStyling : ''}`}>
      {header ? <div className={styles.breadcrumbs}>{header}</div> : null}

      {isLoadingMessages ? (
        <LoadingMessageListPlaceholder />
      ) : (
        <>
          <ChatMessageList applicationState={application.applicationState} messageList={chatMessageList} />
          {checkIfChatWillBeDeleted() ? (
            <div className={styles.infoBox}>
              <InfoBox
                text={t(
                  `deleteApplicationInfo.${user.uid === application?.tenantProfile?.uid ? 'tenant' : 'landlord'}`,
                  { date: printDate(application.deleteDate, t) }
                )}
              />
            </div>
          ) : null}
        </>
      )}

      {checkIfChatIsClosed() ? (
        <div className={styles.closedChat}>
          <Headline align={'center'} size={HEADLINE_SIZE.H3}>
            {t('closedChatInfo.title')}
          </Headline>
          <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT} align={'center'}>
            {t('closedChatInfo.text')}
          </Text>
        </div>
      ) : isLoadingMessages ? (
        <LoadingChatInputPlaceholder />
      ) : (
        <FormContext.Provider value={{ control }}>
          <div className={styles.chatInputWrapper}>
            <form
              name={'chatInputForm'}
              id={'chatInputForm'}
              className={styles.form}
              onSubmit={handleSubmit(submitForm, onError)}
            >
              <TextEditor
                hideError={true}
                hideToolbar={true}
                name={'text'}
                placeholder={t('component.organisms.chat.inputPlaceholder')}
              />

              <CTAButton
                icon={faPaperPlane}
                customStyling={styles.sendButton}
                rounded={false}
                hideText
                buttonText={'send'}
                disabled={submitButtonMode === SUBMIT_BUTTON_MODE.SUBMITTING || watch('text')?.length < 5}
              />
            </form>
          </div>
        </FormContext.Provider>
      )}
    </div>
  );
};

export default Chat;
